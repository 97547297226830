import React from 'react'
import { BrowserRouter as Router , Routes , Route } from "react-router-dom";
import Start from "./components/links"
import Indica from '../src/components/indica';
function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Start/>}/>
        <Route path="/indicacoes" element={<Indica/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
