import React, { useState } from 'react'
import "./style.css"
import logo from "../../assets/logo/chupiscones.png"
import supportSvg from "../../assets/svgs/support.svg"
import bt from "../../assets/svgs/traços.svg"
import cross from "../../assets/svgs/cross.svg"
const Navbar = () => {
  const [modal,setModal] =  useState("modal-off")
  const [btModal,setBtmodal] =  useState("bt")
  return (
    <>
    <div className={modal}>
      <div className="contModal">
        <div className='topModal'>
        <h2>Menu</h2>
        <button onClick={()=>{setModal("modal-off");setBtmodal("bt")}}>
          <img src={cross} alt="" className='cross'/>
        </button>
          </div>
      <ul>
        <button onClick={()=>{setModal("modal-off");setBtmodal("contbutton-on")}}> <li>
          <a href="#sobre">Conheça-nos</a></li></button>
        <button onClick={()=>{setModal("modal-off");setBtmodal("contbutton-on")}}><li><a href="/indicacoes">Indicações</a></li></button>
        <button onClick={()=>{setModal("modal-off");setBtmodal("contbutton-on")}}><li><a href="#con">Contato</a></li></button>
      </ul>
      </div>
    
    </div>
    <div className='navbar'>
      <img src={logo} alt="" className='logo' />
      <div className="links">
     <a href="">
       <button id="bt1" data-text="Awesome">
    <span id="bt1actual-text">&nbsp;SOBRE&nbsp;</span>
    <span aria-hidden="true" id="bt1hover-text">&nbsp;SOBRE&nbsp;</span>
</button>
      </a>
      <a href="/indicacoes">
       <button id="bt1" data-text="Awesome">
    <span id="bt1actual-text">&nbsp;INDICAÇÕES&nbsp;</span>
    <span aria-hidden="true" id="bt1hover-text">&nbsp;INDICAÇÕES&nbsp;</span>
</button>
      </a>
   
      <a href="#con">
       <button id="bt1" data-text="Awesome">
    <span id="bt1actual-text">&nbsp;CONTATO&nbsp;</span>
    <span aria-hidden="true" id="bt1hover-text">&nbsp;CONTATO&nbsp;</span>
</button>
      </a>
      </div>
    <button  className={btModal}onClick={()=>{setModal("modal-on");setBtmodal("contbutton-off")}}><img src={bt} alt="click aqui" className='bt' /></button>
    <div className="support">
      <a href="https://api.whatsapp.com/send?phone=558299097947&text=Ol%C3%A1!%20Estou%20com%20problemas%20no%20site%20Alagoas%20Passeios.%20Voc%C3%AA%20pode%20me%20ajudar?"><button><img src={supportSvg} alt="click aqui" className='support' /></button>
      </a></div>
    </div>
    </>
  )
}

export default Navbar
/*  <h5 ><a href=""id='conheçanos'>CONHEÇA-NOS</a></h5>
  <h5 ><a href=""id='serviços'>SERVIÇOS</a></h5>
  <h5 ><a href=""id='feedbacks'>FEEDBACKS</a></h5>
  <h5 ><a href="" id='contatenos'>CONTATE-NOS</a></h5>*/
