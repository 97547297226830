import React from 'react'
import "./style.css";
import barra1 from "../../assets/imgs/indicacoes/barra-de-sa-miguel/saomiguel1.jpeg";
import barra2 from "../../assets/imgs/indicacoes/barra-de-sa-miguel/barra-sao-miguel-vista-aerea.jpg";
import canyon1 from "../../assets/imgs/indicacoes/canyons-rio-sao-francisco/canyons1.jpeg";
import canyon2 from "../../assets/imgs/indicacoes/canyons-rio-sao-francisco/download.jpg";
import capitao1 from "../../assets/imgs/indicacoes/capitao-nikolas/capitao1.jpeg";
import capitao2 from "../../assets/imgs/indicacoes/capitao-nikolas/download.jpg";
import carneiros1 from "../../assets/imgs/indicacoes/carneiros/carneiros1.jpeg";
import carneiros2 from "../../assets/imgs/indicacoes/carneiros/baia-branca-beach-resort.jpg";
import corais1 from "../../assets/imgs/indicacoes/corais-beach-club/corais1.jpeg";
import corais2 from "../../assets/imgs/indicacoes/corais-beach-club/download.jpg";
import dunas1 from "../../assets/imgs/indicacoes/dunas-de marape/dunas1.jpeg";
import dunas2 from "../../assets/imgs/indicacoes/dunas-de marape/passeios-dunas-de-marape-maceio-capa.jpeg";
import foz1 from "../../assets/imgs/indicacoes/foz-sao-francisco/foz1.jpeg";
import foz2 from "../../assets/imgs/indicacoes/foz-sao-francisco/download.jpg";
import frances1 from "../../assets/imgs/indicacoes/frances/frances1.jpeg";
import frances2 from "../../assets/imgs/indicacoes/frances/download.jpg";
import gunga1 from "../../assets/imgs/indicacoes/gunga/gunga1.jpeg";
import gunga2 from "../../assets/imgs/indicacoes/gunga/download.jpg";
import hibiscus1 from "../../assets/imgs/indicacoes/hibiscus/hibiscus1.jpeg";
import hibiscus2 from "../../assets/imgs/indicacoes/hibiscus/f7.jpg";
import maragogi1 from "../../assets/imgs/indicacoes/maragogi/maragogi1.jpeg";
import maragogi2 from "../../assets/imgs/indicacoes/maragogi/download.jpg";
import paripueira1 from "../../assets/imgs/indicacoes/paripueira/paripueira1.jpeg";
import paripueira2 from "../../assets/imgs/indicacoes/paripueira/download.jpg";
import porto1 from "../../assets/imgs/indicacoes/porto-de-galinhas/porto1.jpeg";
import porto2 from "../../assets/imgs/indicacoes/porto-de-galinhas/download.jpg";
import saomiguel1 from "../../assets/imgs/indicacoes/sao-miguel-milagres/saomiguel1.jpeg";
import saomiguel2 from "../../assets/imgs/indicacoes/sao-miguel-milagres/download.jpg";
import back from "../../assets/svgs/back.png"
import logo from "../../assets/logo/logitos.jpg"
import support from "../../assets/svgs/support.svg"


const indicacoes = [
    {
      name: "Barra de São Miguel",
      img1: barra1,
      img2: barra2,
      key: 1,
      dexription:" Localizada no litoral sul de Alagoas, é conhecida por suas belas praias, mar calmo e coqueirais. A Praia do Gunga é uma das atrações mais famosas da região, com falésias coloridas e piscinas naturais.",
    },
    {
      name: "Canyons do Rio São Francisco",
      img1: canyon1,
      img2: canyon2,
      key: 2,
      dexription:"Embora esteja localizado em Sergipe e não em Alagoas, é uma atração popular para quem visita a região nordeste do Brasil. Os cânions proporcionam um cenário espetacular, com falésias e formações rochosas ao longo do Rio São Francisco.",
    },
    {
      name: "Capitão Nikolas",
      img1: capitao1,
      img2: capitao2,
      key: 3,
      dexription:" É um restaurante localizado na Praia do Gunga, conhecido por sua culinária regional e vista panorâmica para o mar.",
    },
    {
      name: "Carneiros",
      img1: carneiros1,
      img2: carneiros2,
      key: 4,
      dexription:"Apesar de não ser em Alagoas, mas em Pernambuco, é uma praia deslumbrante, famosa por suas águas calmas e cristalinas, piscinas naturais e pela Igreja de São Benedito.",
    },
    {
      name: "Corais beach club",
      img1: corais1,
      img2: corais2,
      key: 5,
      dexription:"Localizado na Praia de Antunes, em Maragogi, é um clube de praia com estrutura para receber turistas, oferecendo atividades e comodidades à beira-mar."
    },
    {
      name: "Dunas de Marapé",
      img1: dunas1,
      img2: dunas2,
      key: 6,
      dexription:"Fica na foz do Rio Jequiá, em Alagoas. É um local com imensas dunas e passeios de buggy bastante populares.",
    },
    {
      name: "Foz do rio São Francisco",
      img1: foz1,
      img2: foz2,
      key: 7,
      dexription:"Onde o rio encontra o mar, este local oferece uma paisagem incrível e passeios de barco para explorar a região.",
    },
    {
      name: "Francês",
      img1: frances1,
      img2: frances2,
      key: 8,
      dexription:"Praia bastante conhecida e frequentada, possui boa estrutura turística, com barracas, bares e lojas.",
    },
    {
      name: "Gunga",
      img1: gunga1,
      img2: gunga2,
      key: 9,
      dexription:"Praia paradisíaca no litoral sul de Alagoas, conhecida pelas falésias coloridas, coqueirais e mar de águas claras.",
    },
    {
      name: "Hibiscus",
      img1: hibiscus1,
      img2: hibiscus2,
      key: 10,
      dexription:"É um bar e restaurante na Praia de Ipioca, em Maceió, com estrutura à beira-mar para turistas.",
    },
    {
      name: "Maragogi",
      img1: maragogi1,
      img2: maragogi2,
      key: 11,
      dexription:"Famosa pelas belas praias e piscinas naturais, é uma das principais atrações de Alagoas, conhecida como o Caribe brasileiro.",
    },
    {
      name: "Mar e cia Paripueira",
      img1: paripueira1,
      img2: paripueira2,
      key: 12,
      dexription:" Restaurante na Praia de Paripueira, oferece culinária típica e uma vista agradável para o mar.",
    },
    {
      name: "Porto de galinhas",
      img1: porto1,
      img2: porto2,
      key: 13,
      dexription:"Embora localizado em Pernambuco, é uma das praias mais famosas do Brasil, conhecida por suas piscinas naturais formadas por recifes e águas cristalinas.",
    },
    {
      name: "São Miguel dos Milagres",
      img1: saomiguel1,
      img2: saomiguel2,
      key: 14,
      dexription:"Conhecida por suas praias tranquilas e piscinas naturais, faz parte da Rota Ecológica, oferecendo um ambiente mais preservado e rústico.",
    },
  ];


const Indica = () => {
  return (
    <div className='indic'>
      <div className="navInclusive">
      <a href="/"><img src={back} alt="" /></a>
      <a href="/"><img src={logo} alt="" className='logo' /></a>
      <a href="https://api.whatsapp.com/send?phone=558299097947&text=Ol%C3%A1!%20Estou%20com%20problemas%20no%20site%20Alagoas%20Passeios.%20Voc%C3%AA%20pode%20me%20ajudar?"><img src={support} alt="" /></a>
      </div>
      <div className="contIndic">
    <ul>
      {indicacoes.map((item)=>(
        <li><div class="card">
       <img src={item.img2} alt="" />
       <div class="card__content">
          <p class="card__title">{item.name}</p>
          <p class="card__description">{item.dexription}</p>
        </div>
      </div>
      </li>
      ))}
      </ul>
      </div>
    </div>
  )
}

export default Indica
