import React from 'react'
import './style.css'
import logo from "../../assets/svgs/chupiscones.svg"
const Sobre = () => {
  return (
    <div className='sobre'>
      <h1>
<button className='tittleDark'>
  Sobre
</button>
</h1>
     <div className="containerSobre">
     <div className="cardSobre">
        <img src={logo} alt="" />
        <h2>Alagoas Turismo</h2>
        <h4>Descubra as maravilhas de Maceió e seus arredores com a Alagoas Tours, uma empresa de passeios turísticos dedicada a proporcionar experiências autênticas e memoráveis. Nossos roteiros cuidadosamente planejados oferecem uma mistura única de praias paradisíacas, aventuras emocionantes e imersão na rica cultura local, tudo isso acompanhado por nossa equipe de guias experientes e apaixonados. Comprometemo-nos a garantir o conforto, a segurança e a satisfação de nossos clientes, tornando cada viagem uma jornada inesquecível de descoberta e diversão. Venha explorar conosco e transforme sua visita a Maceió em memórias que durarão para sempre.</h4>
     </div>
     <div className="mvv">
        <div className="mCont">
        <div className="missao">
        <div className="card-container">
  <div className="card">
  <div className="front-content">
    <p>Missão</p>
  </div>
  <div className="content">
    <p className="heading">Missão</p>
    <h5>
    Encantar e inspirar nossos clientes, proporcionando experiências turísticas únicas e memoráveis em Maceió e seus arredores, através de roteiros cuidadosamente planejados, guiados por nossa paixão pela região e seu patrimônio natural e cultural.
    </h5>
  </div>
</div>
</div>
        </div>
        </div>
        <div className="vCont">
        <div className="visao">
        <div className="card-container">
  <div className="card">
  <div className="front-content">
    <p>Visão</p>
  </div>
  <div className="content">
    <p className="heading">Visão</p>
    <h6>
    Tornar-se a empresa líder em passeios turísticos em Alagoas, reconhecida pela excelência em proporcionar experiências autênticas e marcantes que revelam a essência única da região, impulsionando o desenvolvimento sustentável do turismo local e inspirando viajantes de todo o mundo a explorarem as maravilhas de Alagoas.</h6>
  </div>
</div>
</div>
        </div>
        <div className="valores">  <div className="card-container">
  <div className="card">
  <div className="front-content">
    <p>Valores</p>
  </div>
  <div className="content">
    <p className="heading">Valores</p>
    <h6>
    Excelência: Superar expectativas em cada experiência. <br />
Autenticidade: Proporcionar momentos genuínos e enriquecedores.<br />
Responsabilidade: Agir de forma sustentável e socialmente responsável.<br />
Paixão: Compartilhar nossa paixão por Alagoas e pelo turismo.<br />
Integridade: Agir com honestidade, transparência e respeito.
    </h6>
  </div>
</div>
</div></div>
        </div>
     </div>
     </div>
    </div>
  )
}

export default Sobre
