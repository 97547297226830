import React from 'react'
import "./style.css"
import imgCntt from "../../assets/svgs/inicio.svg"
import whats from "../../assets/svgs/whasapp.svg"
import instagram from "../../assets/svgs/instagram.svg"
import Asside from '../asside'

const Contato = () => {
  return (
    <div className='Contato'>
      <h1>
        <div className="tittleDark">
        Contato
        </div>
        </h1>
      <div className="containerContato">
        <div className='inputs'>
          <div className='contInp'>
          <h3>
          Queremos ouvir de você! Se você tem perguntas, sugestões ou deseja saber mais sobre nossos serviços, por favor, entre em contato. Nossa equipe está pronta para ajudar e responder às suas dúvidas. Não hesite em nos contatar hoje mesmo!
          </h3>
          </div>
        </div>
        <div className="imageCntt">
          <img src={imgCntt} alt="" />
        </div>
      </div>
      <div className="cards">
        <a href="https://www.instagram.com/alagoas_passeios?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
    <div className="card red">
        <p className="tip"><img src={instagram} alt="" /></p>
        <p className="second-text">@alagoas_passeios</p>
    </div>
        </a>
        <a href="https://api.whatsapp.com/message/CAU67BVXREW4O1?autoload=1&app_absent=0">
    <div className="card green">
        <p className="tip"><img src={whats} alt="" /></p>
        <p className="second-text">Whatsapp</p>
    </div>
        </a>
</div>
    </div>
  )
}

export default Contato