import "./style.css"
import personagem from "../../assets/svgs/sapiens.svg"
import React from 'react'

const Indicacoes = () => {
  return (
    <>
      
      <div className="indicacoes">
        <div className="texInd">

        <h1>
          <div className="tittleWithe">
            Indicações
          </div>
        </h1>
        </div>
        <div className="containerIndicacoes">
            <div className="personagemInd">
        <img src={personagem} alt="" />
            </div>
            <div className="textInd">
                <h1>Está Precisando de Dicas de Lugares?</h1>
                <h2>Aqui Tem algumas!</h2>
                <a href="/indicacoes">
                <button class="animated-button">
  <svg viewBox="0 0 24 24" class="arr-2" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
  <span class="text">Indicações</span>
  <span class="circle"></span>
  <svg viewBox="0 0 24 24" class="arr-1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
    ></path>
  </svg>
</button>

                </a>
            </div>
        </div>
      </div>

    </>
  )
}

export default Indicacoes
