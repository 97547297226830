import React from 'react'
import  "./style.css"


const Asside = () => {
  return (
    <div  className='asside'>
      <h6>©copyright from Alagoas passeios 2024</h6>
       
        <h6>Desenvolvido por <a href="https://api.whatsapp.com/send?phone=5582999097947">Lucas Fernando</a></h6>
    </div>
  )
}

export default Asside
