import Contato from "../Contato";
import React, { useState, useEffect } from 'react';
import Indicacoes from "../Indicacoes";
import Asside from "../asside";
import up from "../../assets/svgs/up.svg"
import './style.css'; // Arquivo de estilos CSS para o botão
import Home from "../home";
import Sobre from "../sobre";
function App() {

  const [mostrarBotao, setMostrarBotao] = useState(false);

  // Função para lidar com o evento de rolagem
  const handleScroll = () => {
    if (window.pageYOffset > 100) { // Defina a posição de rolagem na qual o botão deve aparecer
      setMostrarBotao(true);
    } else {
      setMostrarBotao(false);
    }
  };

  useEffect(() => {
    // Adiciona um event listener para o evento de rolagem quando o componente é montado
    window.addEventListener('scroll', handleScroll);
    // Remove o event listener quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Executa apenas uma vez durante a montagem do componente

  // Função para rolar para o topo da página quando o botão é clicado
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Adiciona um comportamento de rolagem suave
    });
  };


  return (
   <div className="all">
   <button className={`botao-scroll ${mostrarBotao ? 'mostrar' : 'esconder'}`} onClick={scrollToTop}>
   <img src={up} alt="" height={"4vh"}/>
    </button>
   <Home/>
   <section id="sobre">
   <Sobre/>
   </section>
   <Indicacoes/>
   <section id="con">
   <Contato />
   </section>
   <Asside/>
   </div>
  );
}

export default App;
